.remainingLettersContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 500px;
    margin-bottom: 5px;
}

.keyboardRow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 28%;
    margin-top: 5px;
}

.remainingLetterCell {
    cursor: pointer;
    height: 90%;
    margin: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-family: 'Clear Sans', 'Helvetica Neue', Arial, sans-serif;
    font-weight: bold;
    color: black;
    background-color: #d3d6da;
    border-radius: 5px;
}

.gone {
    background-color: white;
}

.green {
    cursor: default;
    background-color: #6AAA64;
    color: white;
}

.yellow {
    cursor: default;
    background-color: #C9B458;
    color: white;
}

.orange {
    cursor: default;
    background-color: #F69623;
    color: white;
}
.gray {
    cursor: default;
    background-color: #787C7E;
    color: white;
}

.firstRow {
    width: 100%;
}

.secondRow {
    width: 90%;
}

.thirdRow {
    width: 100%;
}

.firstRowCell {
    width: 10%;
}

.secondRowCell {
    width: 11%;
}

.thirdRowCell {
    width: 11%;
}

.wideCharacter {
    width: 17%;
    /*height: 44px;*/
    height: 100%;
}