.previousBoardsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    height: 100%;
}

.boardContainer {
    width: 100%;
    background-color: beige;
    border-radius: 10px;
    max-width: 180px;
}

.previousBoardRow {
    display: flex;
    flex-direction: row;
    height: 18%;
    width: 100%;
    margin-top: 2px;
}

.previousBoardCell {
    cursor: pointer;
    height: 100%;
    width: 18%;
    margin-right: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-family: 'Clear Sans', 'Helvetica Neue', Arial, sans-serif;
    font-weight: bold;
    color: white;
    box-sizing: border-box;
}