.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
}

.outcome-modal {
    padding: 20px;
    margin: 40px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    margin-bottom: 200px;
}